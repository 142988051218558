<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>自助点列表</p>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 添加自助点</el-button>
        <!-- @click="add()" -->
      </div>
      <div class="query">
        <div class="queryLeft">
          <div style="width: auto;">区：
            <el-select class="select" filterable v-model="serchList.area" size="medium" placeholder="请选择区"
              style="width: auto;" @change="areaSelect">
              <el-option v-for="item in area_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: auto;">&nbsp;自助点：
            <el-select class="select" filterable v-model="serchList.id" size="medium" placeholder="请选择自助点"
              style="width: auto;" @change="zzdSelect">
              <el-option v-for="item in zzdDate" :key="item.id" :label="item.zzdName" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="zzdListFunSearch(serchList,1)">查询</el-button>
        </div>
      </div>

      <el-table :data="shopInfos" v-loading="loading">
        <el-table-column prop="serial" label="序号" type="index" align="center" width="auto">
          <template slot-scope="scope">
            {{ (currpage - 1) * pagesize + scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="zzd_name" label="自助点名称" align="center" width="auto">
        </el-table-column>
        <el-table-column prop="ssq" label="自助点位置" align="center" width="auto">
          <template slot-scope="scope">
            <!-- {{scope.row.pname }} {{scope.row.cname }}-->
            {{scope.row.addr }}{{scope.row.detail_addr }}
          </template>
        </el-table-column>
        <el-table-column prop="dly_num" label="训练仪设备(台)" align="center"></el-table-column>
        <el-table-column prop="create_time" label="加入时间" width="auto" align="center">
        </el-table-column>
        <!--        <el-table-column label="操作" fixed="right" align="center" width="auto">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain v-if="scope.row.TeaName==null?true:false"
              @click="detail(scope.$index, scope.row)">详情</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block" v-show="CountNum > 0">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加自助锻炼点" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="form" :model="formAdd">
        <el-form-item label="锻炼点名称">
          <el-input v-model="formAdd.zzd_name" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="所在区县">
          <el-select class="select"  v-model="formAdd.area" size="medium" placeholder="请选择区"
            style="width: auto;">
            <el-option v-for="item in area_data_add" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="门店位置">
           <map-position  @update="mapChange" />
<!--
          <el-select class="select"  v-model="formAdd.addr" size="medium" placeholder="请选择区"
            style="width: auto;">
            <el-option v-for="item in area_data_add" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select> -->
        </el-form-item>

        <el-form-item label="详细地址">
          <el-input v-model="formAdd.detail_addr" style="width: auto;"></el-input>
        </el-form-item>

        <el-form-item label="锻炼点照片">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingDld"
            :file-list="dldList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgDld"
            :headers="{'token':token}" :on-success="fileSuccessDld" :on-error="imgErrorDld" :on-exceed="limitAlertDld"
            style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="合作协议/房屋租赁合同">
          <el-upload ref="upload" :action="imgurl" list-type="picture-card" :limit="1" :on-progress="uploadingHt"
            :file-list="htList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImgHt"
            :headers="{'token':token}" :on-success="fileSuccessHt" :on-error="imgErrorHt" :on-exceed="limitAlertHt"
            style="height: 145px;">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit(1)" >提交</el-button>
          <el-button  @click="onSubmit(2)" >取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


  </div>


</template>

<script>
  import {
    zzdList,
    addZzd,
    machineSsq
  } from '@/api/agentApi.js'
  import request from '@/api/request.js'
  import MapPosition from '../../components/MapPosition.vue'

  export default {
    components: {
      MapPosition
    },
    data() {
      return { //声明属性变量 可以用this.属性名得到 例如 this.pagesize
        imgurl: this.$url + '/upload/img', //上传图片请求地址(线上)
        shopInfos: [], // 自助点列表
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        loading: false, //加载框
        serchList: {}, //查询列表
        serchListCopy: {}, //查询列表
        zzdDate: [], //自助点下拉
        zzdDateCopy: [], //自助点下拉
        area_data: [], //区下拉
        area_data_add: [], //区下拉添加自助点时
        dialogFormVisible: false, // 添加自助点弹窗
        Addfacility: {
          facility: '' // 添加的设备编号
        },
        file: [], //上传的file数组
        token: localStorage.getItem('token'), //token
        formAdd: {
          zzd_name : '',
          area : '',
          addr : '',
          detail_addr : '',
        } ,//表单
        dldList: [],//锻炼点图片
        htList: []//合同图片
      }
    },
    mounted() {
      this.zzdMachineListFun({}, 1) // 获取自助点列表
      this.zzdMachineListFun2() // 获取自助点下拉
      this.areaChange() // 获取区
    },
    methods: {
      reset() { //重置按钮
        this.serchList = {}
        this.serchListCopy = {}
        this.zzdMachineListFun({}, 1)
      },
      InsertTime(row, column) { // 加入时间过滤
        return row.InsertTime
        // return row.InsertTime.slice(0, 16)
      },
      zzdListFunSearch(obj, b) { // 搜索功能
        this.serchListCopy = JSON.parse(JSON.stringify(obj)) //深拷贝
        this.zzdMachineListFun(obj, 1)
      },
      zzdMachineListFun(serchJson, currpage) { // 自助点列表
        this.loading = true
        const data = serchJson

        data["page"] = currpage //	当前页数
        data["limit"] = this.pagesize // 每页条数

        zzdList(data).then(res => {
          this.shopInfos = res.data.list // 自助点列表
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.currpage = currpage //分页显示当前页
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      zzdMachineListFun2() { // 下拉框获取自助点列表
        const data = {}
        data["page"] = 1 //	当前页数
        data["limit"] = 1000 // 每页条数
        data["list_type"] = 1 //
        zzdList(data).then(res => {
          this.zzdDate = res.data.list.map((item, index) => {
            return Object.assign({}, {
              'zzdName': item.zzd_name
            }, {
              'id': item.id
            }, {
              'area': item.area
            })
          })

          this.zzdDate.unshift({
            zzdName: '全部自助点',
            id: 0,
            area: 0,
          })
          this.zzdDateCopy = [...this.zzdDate]
        }).catch(err => {
          console.log(err)
        })
      },
      detail(index, row) { // 点击详情
        //console.log(row);
        this.$router.push({
          name: 'ZzdList_detail',
          params: {
            Schinfos: row.id
          }
        })
        localStorage.setItem('lxzZzdName', row.zzd_name) // 存自助点名字
      },
      handleCurrentChange(val) { // 分页
        this.zzdMachineListFun(this.serchListCopy, val)
        // this.currpage = val
      },
      areaChange() {
        const datas = {
          cid: JSON.parse(localStorage.getItem('userInfo')).city,
        }
        machineSsq(datas).then(res => {
          this.area_data = res.data.area;

          this.area_data.unshift({
            id: 0,
            name: '全部地区'
          })

          this.area_data_add = res.data.area.filter(item => item.id != 0)

        }).catch(err => {
          console.log(err)
        })
      },
      areaSelect(e) {
        this.serchList.id = '';
        //因为是联动的 所以需要 定一个area_id 用来处理选中完门店 渲染不上去
        this.area_id = this.area_data.find(item => item.id == e)

        if (e == 0) {
          this.zzdDate = this.zzdDateCopy;
        } else {
          this.zzdDate = this.zzdDateCopy;
          this.zzdDate = this.zzdDate.filter(item => item.area == e || item.area == 0)
        }
      },
      zzdSelect(e) {
        //先清空区下拉 然后赋值渲染
        this.serchList.area = ''
        this.serchList.area = this.area_id.id
        this.serchList.id = e;
      },
      onSubmit(type) {
        if(type == 1){

          if(this.formAdd.zzd_name == "" || this.formAdd.area == "" ||  this.formAdd.addr == "" ||  this.formAdd.detail_addr == ""){
            this.$message.error('请补全信息')
            return
          }
          if(this.dldList.length == 0){
            this.$message.error('请上传锻炼的照片')
            return
          }
          if(this.htList.length == 0){
            this.$message.error('请上传合同的照片')
            return
          }

          const data = {
              area            : this.formAdd.area,
              zzd_name        : this.formAdd.zzd_name,
              addr            : this.formAdd.addr,
              detail_addr     : this.formAdd.detail_addr,
              dld_pic         : this.dldList[0].url,
              ht_pic          : this.htList[0].url
          }
          addZzd(data).then(res => {

            if(res.status.code == 1){
              this.$message.success('提交成功 请等待审核')
              this.dialogFormVisible = false;

              this.formAdd =  {
                zzd_name : '',
                area : '',
                addr : '',
                detail_addr : '',
              } ;//表单
              this.dldList = [];//锻炼点图片
              this.htList  = [];//合同图片

            }else{
              this.$message.error(res.status.msg)
            }
          }).catch(err => {
            console.log(err)
          })

        }else{
          this.dialogFormVisible = false;
        }
      },
      uploadingDld(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.dldList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.dldList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertDld() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorDld(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgDld(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.dldList = this.dldList.filter(item => item.imgId != file.imgId) //从dldList 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessDld(response, file, fileList) { //展示图片上传成功
        this.$message.success('图片上传成功！')
        let data = {
          imgId: file.uid,
          url: response.data.url
        }
        this.dldList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给dldList

      },

      uploadingHt(event, file, fileList) { //判断用户上传文件类型和大小
        if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png') {
          const size = file.size / 1024
          if (size > 1024) {
            this.$message.error('图片大小不能超过1M！')
            this.$refs.upload.abort() //取消上传
            this.imgList = fileList.filter(item => item.uid != file.uid)
          }
        } else {
          this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
          this.imgList = fileList.filter(item => item.uid != file.uid)
          this.$refs.upload.abort() //取消上传
        }
      },
      limitAlertHt() { //展示图片超过张数提醒
        this.$message.error('最多能上传1张图片！')
      },
      imgErrorHt(err, file, fileList) { //展示图片上传失败提醒
        this.$message.error('图片上传失败！')
      },
      removeImgHt(file, fileList) { //展示图片删除提醒
        this.$message.success('图片删除成功！')
        this.htList = this.htList.filter(item => item.imgId != file.imgId) //从imgData 过滤掉 item.imgId 不等于 file.uid
      },
      fileSuccessHt(response, file, fileList) { //展示图片上传成功
          this.$message.success('图片上传成功！')
          let data = {
            imgId: file.uid,
            url: response.data.url
          }
          this.htList.push(data); //把上传成功的返回的地址（一般是oss地址） 赋值给htList
      },
      mapChange(posData) {
        this.formAdd.addr = posData.addr;
      }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;

        div {
          width: 22%;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }
</style>
